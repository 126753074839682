import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "../style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Accordion, Card, Container } from "react-bootstrap"

interface Q {
  question: string
  answer?: string
  answers?: string[]
}

const questions: Q[] = [
  {
    question: "Wat is orthodontie?",
    answer:
      "Orthodontie is een specialisatie binnen de tandheelkunde die zich bezig houdt met de studie, begeleiding en behandeling van de onregelmatige stand van tanden en kaken, en dit zowel bij kinderen als volwassenen.",
  },
  {
    question: "Wat zijn de voordelen van orthodontie?",
    answers: [
      "Esthetisch: Een aantrekkelijke glimlach verbetert het voorkomen en aldus het zelfvertrouwen.",
      "Functioneel: Een correcte tandstand en beet is uiterst belangrijk voor het goed functioneren van het gebit. Abnormale slijtage van tanden wordt tegengegaan en het kaakgewricht kan normaal functioneren.",
      "Minder traumagevaar: Het risico op beschadiging van vooruitstekende tanden neemt af bij ongeval.",
      "Verbetering van de mondhygiëne: Goed uitgelijnde tanden zijn makkelijker te poetsen waardoor de kans op cariës en tandvleesontstekingen aanzienlijk vermindert.",
    ],
  },
  {
    question: "Welke orthodontische afwijkingen zijn er?",
    answer:
      "Er zijn een heleboel orthodontische afwijkingen. Men kan ze algemeen in twee grote categorieën opdelen.",
    answers: [
      "Afwijkingen in de hoeveelheid groei van de kaken: Dit kan voor-achterwaarts (vb. te weinig groei van de onderkaak kan resulteren in vooruitstekende boventanden) of transversaal met een asymmetrisch gelaat als gevolg (scheve beet).",
      "Afwijkingen van de tandstand binnen de kaak: overlappingen, spleetjes, rotaties, kippingen, ingesloten tanden, te veel of te weinig tanden, ...",
    ],
  },
  {
    question:
      "Wat is de ideale leeftijd om voor de eerste maal naar de orthodontist te gaan?",
    answer:
      "Aangezien het de taak is van de orthodontist om ook de gebitswisseling te begeleiden, raden we aan om vanaf 8 jaar een eerste bezoek te plannen. In de meeste gevallen zal dit gelukkig nog te vroeg zijn voor de start van een orthodontische correctie. Onregelmatigheden kunnen op deze leeftijd al gedetecteerd worden en zonodig reeds begeleid. ",
  },

  {
    question:
      "Op welke leeftijd kan een orthodontische behandeling uitgevoerd worden?",
    answer:
      "Een orthodontische behandeling kan op elke leeftijd, dus ook bij volwassenen. Wel moet de conditie van de tanden en de weefsels rondom (tandvlees en kaakbot) gezond zijn. De meeste behandelingen worden in de leeftijdscategorie van 10 tot 15 jaar uitgevoerd. Tijdens deze periode kan de groei van de kaken nog beïnvloed worden met bepaalde beugels en kan de ruimte, die bij het wisselen van de melktanden vrijkomt, positief benut worden. Bij een volwassen patiënt kunnen de tanden ook verplaatst worden binnen de kaak, maar voor een kaakcorrectie zal hier chirurgie noodzakelijk zijn. ",
  },

  {
    question:
      "Is een elektrische tandenborstel beter dan een gewone tandenborstel?",
    answer:
      "Ja, uit onderzoek is gebleken dat de elektrische borstels nog beter de plak verwijderen. De elektrische tandenborstel maakt de juiste poetsbeweging voor u. Daardoor kunt u zich makkelijker concentreren op het juist plaatsen van de borstelkop. Bij kinderen draagt dit bij in de motivatie om het gebit goed te verzorgen, zij vinden het in elk geval leuker. ",
  },
  {
    question: "Zijn er ook risico's bij een orthodontische behandeling?",
    answer:
      "Alle vormen van medische en tandheelkundige behandelingen kennen enkele risico's. Gelukkig zijn de complicaties in de orthodontie zeldzaam en indien ze dan toch optreden, hebben ze een gering effect. ",
    answers: [
      "Indien de patiënt onvoldoende poetst, kunnen tandvleesontsteking, cariës en een blijvende tandverkleuring (ontkalking) veroorzaakt worden.",
      "Het gebeurt wel eens dat een orthodontische tandverplaatsing een afname in de wortellengte veroorzaakt. Meestal heeft dit geen significante gevolgen, maar occasioneel kan dit de levensduur van de betrokken tanden benadelen.",
      "In enkele gevallen (na een vroeger trauma) kan een tand bij verplaatsing een grijze verkleuring krijgen, dit wijst op een slechte bloedtoevoer tijdens de tandverplaatsing met afsterven van de zenuw tot gevolg. Een wortelkanaalbehandeling biedt hier dan oplossing.",
    ],
  },
]

const Faq = () => (
  <Layout sectionTitle={"Veelgestelde vragen"}>
    <SEO title="Faq" />
    <Container className="text-justify">
      <Accordion defaultActiveKey="0" style={{ paddingTop: "20px" }}>
        {questions.map((q, index) => (
          <Card
            key={`q-card-${index}`}
            style={{ border: "none", padding: "10px" }}
          >
            <Accordion.Toggle
              eventKey={`q-toggle-${index}`}
              style={{ background: "none", border: "none" }}
            >
              <h2>{q.question}</h2>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`q-toggle-${index}`}>
              <Card.Body>
                {q.answer && <p>{q.answer}</p>}
                {q.answers && (
                  <ul>
                    {q.answers.map((answer, i) => (
                      <li key={`answer-${index}-${i}`}>{answer}</li>
                    ))}
                  </ul>
                )}
              </Card.Body>
            </Accordion.Collapse>
            <noscript>
              {q.answer && <p>{q.answer}</p>}
              {q.answers && (
                <ul>
                  {q.answers.map((answer, i) => (
                    <li key={`answer-${index}-${i}`}>{answer}</li>
                  ))}
                </ul>
              )}
            </noscript>
          </Card>
        ))}
      </Accordion>
    </Container>
  </Layout>
)

export default Faq
